<template>
	<!-- 仓库资料 -->
	<div class="warehouseData">
		<div class="search">
			<div class="search-l">
				<div class="l-item">
					<div class="item-label">交易月份:</div>
					<div class="item-input">
						<el-date-picker v-model="searchForm.month" type="month" placeholder="请选择月份"
							value-format="yyyyMM">
						</el-date-picker>
					</div>
				</div>
				<div class="l-item">
					<div class="item-label">订单号:</div>
					<div class="item-input">
						<el-input v-model="searchForm.keyword" placeholder="请输入交易订单号或商户订单号" />
					</div>
				</div>
				<div class="l-item">
					<div class="item-label">收款码牌:</div>
					<div class="item-input">
						<el-select v-model="searchForm.code_plate_id" placeholder="请选择收款码牌" style="width:100%">
							<el-option v-for="item in deviceList" :key="item.id" :label="item.remark" :value="item.id">
							</el-option>
						</el-select>
					</div>
				</div>
				<div class="l-item">
					<el-button type="warning" @click.stop="page = 1, loading = true, getList()">搜索</el-button>
					<el-button
						@click.stop="loading = true, page = 1, pageNum = 10, searchForm = {}, getList()">重置</el-button>
				</div>
				<i></i>
				<i></i>
				<i></i>
				<i></i>
			</div>
		</div>
		<div class="table">
			<div class="table-operation">
				<el-button type="info" icon="el-icon-refresh-left" @click.stop="loading = true, getList()"></el-button>
			</div>
			<Table :data="tableData" :total="total" v-loading="loading"
				@size-change="pageNum = $event, loading = true, getList()"
				@current-change="page = $event, loading = true, getList()">
				<el-table-column label="交易时间" prop="created_at" align="center" width="160"></el-table-column>
				<el-table-column label="交易订单号" prop="mer_ord_id" align="center" width="280"></el-table-column>
				<el-table-column label="交易金额" prop="trans_amt" align="center" width="120">
					<template slot-scope="scope">
						<span v-if="scope.row.type == 0"
							style="color:red;font-weight: 600;font-size:16px;">+{{scope.row.trans_amt}}</span>
						<span v-else
							style="color:green;font-weight: 600;font-size:16px;">-{{scope.row.trans_amt}}</span>
					</template>
				</el-table-column>
				<el-table-column label="收款方" prop="mer_name" align="center" width="160"></el-table-column>
				<el-table-column label="收款码牌" align="center" width="200">
					<template slot-scope="scope">
						<p>{{scope.row.code_plate_sn}}</p>
						<p>{{scope.row.code_plate_remark}}</p>
					</template>
				</el-table-column>
				<el-table-column label="交易方式" prop="print_name" align="center" width="80">
					<template slot-scope="scope">
						<span v-if="scope.row.trans_type == 'T_JSAPI'" style="color:green">微信</span>
						<span v-else style="color:royalblue">支付宝</span>
					</template>
				</el-table-column>
				<el-table-column label="商户订单号" prop="out_trans_id" align="center" width="280"></el-table-column>
				<el-table-column label="备注" prop="remark" align="center"></el-table-column>
				<el-table-column label="操作" align="center" width="160">
					<template slot-scope="scope">
						<el-button type="danger" size="mini" @click.stop="openDialog(scope.row)"
							v-if="scope.row.canRefund">退款</el-button>
					</template>
				</el-table-column>
			</Table>
		</div>
		<!-- 新增 -->
		<el-dialog :title="'退款'" :visible.sync="dialogVisible" :close-on-click-modal="false" v-el-drag-dialog
			width="30%" v-loading="loading">
			<el-form ref="form" :model="form" label-width="120px">
				<el-form-item label="退款金额" required>
					<el-input v-model="form.refund_amt"
						:placeholder="'请输入退款金额,当前最大退款金额'+selectedItem.trans_amt"></el-input>
				</el-form-item>
			</el-form>
			<span slot="footer" class="dialog-footer">
				<el-button @click.stop="dialogVisible = false">取 消</el-button>
				<el-button type="warning" @click.stop="confirmFn">确 定</el-button>
			</span>
		</el-dialog>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				tableData: [],
				total: 0,
				page: 1,
				pageNum: 10,
				loading: true,
				// 新增、编辑弹窗
				dialogType: 1, //1新增2编辑
				dialogVisible: false,
				plDialogVisible: false,
				searchForm: {
					month: '',
					keyword: ''
				},
				form: {
					refund_amt: ''
				},
				deviceList: [],
				selectedItem: {}
			}
		},
		mounted() {
			this.searchForm.month = this.getCurrentMonth()
			this.getList()
			this.getDeviceList()
		},
		methods: {
			getDeviceList() {
				this.$http.post(`/erp/v1/code_plate/all`, {}).then((res) => {
					const {
						code,
						data,
						msg
					} = res.data
					this.loading = false
					if (code == 200) {
						this.deviceList = data
					} else {
						this.$message.error(msg);
					}
				});
			},
			getCurrentMonth() {
				const today = new Date();
				let year = today.getFullYear();
				let month = (today.getMonth() + 1).toString().padStart(2, '0');
				let day = today.getDate().toString().padStart(2, '0');
				return `${year}${month}`;
			},
			openDialog(item) {
				this.selectedItem = item
				this.form = {
					refund_amt: '',
					id: item.id,
					month: item.trans_date.substring(0, 6)
				}
				this.dialogVisible = true
			},
			getList() {
				this.$http.post(`/erp/v1/code_plate/transLog`, this.searchForm).then((res) => {
					const {
						code,
						data,
						msg
					} = res.data
					this.loading = false
					if (code == 200) {
						this.tableData = data.rows
						this.total = data.total
					} else {
						this.$message.error(msg);
					}
				});
			},
			// 确定
			confirmFn() {
				this.loading = true
				let url = '/erp/v1/code_plate/transRefund'
				this.$http.post(`${url}`, this.form).then((res) => {
					const {
						code,
						msg
					} = res.data
					this.loading = false
					if (code == 200) {
						this.$message.success(msg);
						this.dialogVisible = false
						this.getList()
					} else {
						this.$message.error(msg);
					}
				});
			},
		},
	}
</script>

<style lang="scss" scoped></style>